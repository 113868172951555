<template>
  <v-footer padless dark v-if="!isAuthComponent">
    <v-card class="flex" flat :color="theme ? '' : 'primary'" min-height="200px">
      <v-container>
        <v-row>
          <v-col>
            <v-card flat color="transparent">
              <v-card-title>
                <v-img  class="ml-n7" width="206px" height="52px" :src="require('@/assets/logowhite.svg')"></v-img>
              </v-card-title>

              <v-card-actions>
                <div class="mt-10">
                  <span> Social</span>
                  <div class="mt-3 d-flex">
                    <a target="__blank" href=" https://discord.gg/8y9ZPGm3Wq" class="white--text"><i class="fab fa-discord fa-lg"></i> </a>
                    <a target="__blank" href="https://www.linkedin.com/company/74531880" class="ml-2 white--text"> <i class="fab fa-linkedin fa-lg"></i> </a>
                    <a target="__blank" href="https://github.com/The-Eigensolvers/" class="ml-2 white--text"><i class="fab fa-github-square fa-lg"></i></a>
                    <a target="__blank" href=" https://www.youtube.com/channel/UCYAKRX39svr_h3o_TEDbxQg" class=" ml-2 white--text"><i class="fab fa-youtube fa-lg"></i></a>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat color="transparent" width="142px">
              <v-card-title class="white--text">
                Other Work
              </v-card-title>
              <v-card-text class="white--text">
                The Eigensolvers have other projects going on, follow us on LinkedIn and GitHub for more details. 
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card flat color="transparent" width="145px">
              <v-card-title class="white--text">
                Contribute
              </v-card-title>
              <v-card-text class="white--text">
                You can contribute by applying in the contribution page here. You will most likely  get a response within a few hours              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card flat color="transparent" width="171px">
              <v-card-title class="white--text">
                Community
              </v-card-title>
              <v-card-text class="white--text">
                haq aims at not only being an education and entertainment platform, but also bringing together a global community of QC enthusiasts 
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'Footer',
  mixins: [colorMxn],
  data() {
    return {
    }
  },
  computed: {
    isAuthComponent() {
      const routeNames = [
        'Login',
        'Auth',
        'Register',
        'Dashboard',
        'Challenges',
        'ForgotPassword',
        'ResetPassword',
        'ProblemEditor',
        'ChallengeOverview',
        'CodeEditor'
      ]
      // const result = this.$route.name === 'Login' || this.$route.name === "Auth" || this.$route.name === "Register" || this.$route.name === "Dashboard" || this.$route.name === "Challenges" || this.$route.name === "ForgotPassword" || this.$route.name === "ResetPassword"; 
      const result = routeNames.find((item) => item === this.$route.name);
      return result;
    }
  }
}
</script>
